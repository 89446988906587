import React from 'react';
import Layout from '../../layouts/Layout';
import JobAlerts from '../../components/JobAlerts/JobAlerts';
import { graphql, useStaticQuery } from 'gatsby';
import CTA from '../../components/CTA/CTA';
import HeroImage from '../../components/HeroImage/HeroImage';
import JobSearchForm from '../../components/JobSearchForm/JobSearchForm';
import teamHeroImg from '../../images/team-hero.png';
import TextSection from '../../components/TextSection/TextSection';
import JobCategories from '../../components/JobCategories/JobCategories';
import Benefits from '../../components/Benefits/Benefits';
import OurFocus from '../../components/OurFocus/OurFocus';
import LargeImageCarousel from '../../components/LargeImageCarousel/LargeImageCarousel';
import SpecialPrograms from '../../components/SpecialPrograms/SpecialPrograms';

import carouselImage1 from './carouselImage1.jpg';
import carouselImage2 from './carouselImage2.jpg';
import carouselImage3 from './carouselImage3.jpg';
import carouselImage4 from './carouselImage4.jpg';
import carouselImage5 from './carouselImage5.jpg';
import carouselImage6 from './carouselImage6.jpg';
import carouselImage7 from './carouselImage7.jpg';
import carouselImage8 from './carouselImage8.jpg';

const WorkHerePage = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'About Us',
			href: '/',
			active: false
		},
		{
			text: 'Careers',
			href: '/jobs/',
			active: false
		},
		{
			text: 'Meet the Team',
			href: '/team/',
			active: false
		},
		{
			text: 'Working Here',
			href: '/work-here/',
			active: false
		}
	];

	const carouselImages = [
		{ imgSrc: carouselImage1 },
		{ imgSrc: carouselImage2 },
		{ imgSrc: carouselImage3 },
		{ imgSrc: carouselImage4 },
		{ imgSrc: carouselImage5 },
		{ imgSrc: carouselImage6 },
		{ imgSrc: carouselImage7 },
		{ imgSrc: carouselImage8 }
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Genpak Working Here'}
			desc={'Genpak Working Here'}
		>
			<HeroImage imgSrc={teamHeroImg} />
			<JobSearchForm
				data={data}
				h1={'Take pride in your career!'}
				p={
					'Find a rewarding profession at Genpak that helps enhance peoples’ lives and is something you can be proud of.'
				}
			/>
			<JobCategories />
			<TextSection
				h2={'What We Believe'}
				p={
					'Genpak provides an open and rewarding work atmosphere. Team members are encouraged to share solutions and contribute ideas that help our processes. Genpak, in turn offers great benefits and competitive pay.'
				}
			/>
			<Benefits />
			<OurFocus />
			<SpecialPrograms />
			<TextSection
				h2={'Take a Look Around'}
				p={
					'What does a typical day in the life of our talented teammates look like? Come see for yourself.'
				}
			/>
			<LargeImageCarousel images={carouselImages} />
			<CTA
				h2={'Ready to get started?'}
				p={
					'Genpak is growing and wants you to join our successful team today!'
				}
			/>
			<JobAlerts />
		</Layout>
	);
};

export default WorkHerePage;
