import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';

import './Carousel.css';

interface LargeImageCarouselProps {
	images: Array<{
		imgSrc: string;
	}>;
}
const LargeImageCarousel: React.FC<LargeImageCarouselProps> = (
	props: LargeImageCarouselProps
) => {
	SwiperCore.use([Autoplay, Pagination, Navigation]);
	return (
		<section className={'LargeCarousel px-3 pt-0 text-center	'}>
			<div className={'container mx-auto'}>
				<Swiper
					modules={[Navigation, Pagination]}
					className={'mb-8 w-[950px] max-w-full max-sm:mt-8'}
					spaceBetween={50}
					loop
					navigation
					pagination={{ clickable: true }}
					autoplay={{ delay: 3000 }}
					slidesPerView={1}
				>
					{props.images.map((image, index) => {
						return (
							<SwiperSlide>
								<div
									className={
										'px-20 pb-10 max-sm:py-0 max-sm:px-0'
									}
								>
									<img
										className={'rounded shadow'}
										alt={`Carousel image ${index}`}
										src={image.imgSrc}
									/>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</section>
	);
};

export default LargeImageCarousel;
