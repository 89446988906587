import * as React from 'react';

const SpecialPrograms: React.FC = () => {
	const programs = [
		{
			title: 'Leadership Development',
			blurb: 'Genpak is committed to developing the corporate leaders of tomorrow. Our program combines employee engagement with expert shadowing.'
		},
		{
			title: 'Second Chance Employer',
			blurb: 'Our company empowers formerly incarcerated individuals to dive back into the job market and obtain conmpetitive jobs without benefits.'
		},
		{
			title: 'Career Development',
			blurb: 'Continuously improve your skillset while navigating various roles and responsabilities. Grow within the organization while contribuiting to its success.'
		},
		{
			title: 'Sustainable Product Development',
			blurb: 'Join our team as we continue to develop the future of food service packaging. Our sustainable product innovation is industry-leading.'
		}
	];

	return (
		<section>
			<div className={'container mx-auto flex items-center px-5 py-10'}>
				<div>
					<h2
						className={
							'pb-6 text-center font-lato text-3xl font-bold'
						}
					>
						Special Programs
					</h2>
					<div className="flex flex-col gap-10">
						<div>
							{programs.map((program) => {
								return (
									<div className="flex flex-col gap-6 border-t-2 border-gray-400 p-6">
										<h3 className="text-2xl text-gp-green">
											{program.title}
										</h3>
										<p className="text-lg">
											{program.blurb}
										</p>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default SpecialPrograms;
